// pardon the hack, kanvas couldn't get the envar properly
process.env = {
  REACT_APP_API_URL: process.env.API_URL,
};
import React from 'react';
import ReactDOM from 'react-dom';
import { FeatureFlagProvider } from '@feedloop/feature-flag';
import decode from 'jwt-decode';
import axios from 'axios';
import * as Sentry from '@sentry/browser';
import App from './App';
import '@feedloop/kanvas/lib/i18n';
import FeatherSprite from '!babel-loader!react-svg-loader!./assets/icon/feather-sprite.svg';
import 'emoji-mart/css/emoji-mart.css';
import Storage from './utils/storage';
const app = require('../package.json');

if (
  process.env.ENVIRONMENT &&
  (process.env.ENVIRONMENT === 'staging' || process.env.ENVIRONMENT === 'production')
) {
  Sentry.init({
    environment: process.env.ENVIRONMENT === 'production' ? 'production' : 'staging',
    dsn: 'https://bdf87897ce1a4b8e960bdb9bbfc9faef@sentry.io/1505811',
    release: app && app.version ? `v${app.version}` : 'UNDEFINED RELEASE VERSION',
  });
}

(async () => {
  const { data: features } = await axios.get<{
    data: {
      'Release Channel': string;
      'Release ID': string;
      'Alpha Users': string;
    }[];
  }>('https://g-api.feedloop.io/v1/proxies/coda');
  let viewer = 'default';

  try {
    const token = Storage.load('token');
    const payload = decode<Record<string, any>>(token.token);
    viewer = payload['https://feedloop.io'].email;
  } catch (error) {}

  ReactDOM.render(
    <FeatureFlagProvider
      viewer={viewer}
      channel={process.env.RELEASE_CHANNEL || 'Staging'}
      features={features.data.map(feat => ({
        name: feat['Release ID'],
        channel: feat['Release Channel'].split(','),
        viewers: !!feat['Alpha Users'] ? feat['Alpha Users'].split(',') : [],
      }))}
    >
      <App />
    </FeatureFlagProvider>,
    document.getElementById('root')
  );

  const preloadElement = document.createElement('div');
  preloadElement.style.display = 'none';
  ReactDOM.render(<FeatherSprite />, preloadElement);
  const root = document.getElementById('root');
  root && root.appendChild(preloadElement);
})();
