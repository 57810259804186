import React from 'react';
import dotenv from 'dotenv';
import axios, { AxiosResponse } from 'axios';
import { Store } from '../store';
import { GlobalStore } from '../interfaces/store';
import { APICampaign, APICampaignStatus } from '../interfaces/campaign';
import { Campaign } from '@feedloop/template-engine';
import CampaignResponses from './interfaces/CampaignResponses';
import CampaignMixer from '../utils/campaignMixer';
import { Variable } from '@feedloop/template-engine/lib/campaign';
import { KanvasCampaign } from '@feedloop/kanvas/lib/editor/KanvasEditorContext';

dotenv.config();

const generateSubdomain = (length: number) => {
  return Math.round(Math.pow(36, length + 1) - Math.random() * Math.pow(36, length))
    .toString(36)
    .slice(1);
};

interface APICampaignResponse {
  data: {
    results: {
      sessionGuid: string;
      answers: {
        attributes: {
          title: string;
        };
        answer: string[];
      }[];
      pageResult: string;
    }[];
    total: string;
  };
}

interface APICampaignTemplateResponse {
  data: {
    templates: {
      guid: string;
      createdAt: string;
      updatedAt: string;
      title: string;
      description: string;
      type: 'kanvas' | 'legacy';
      published: boolean;
      data: KanvasCampaign;
    }[];
  };
}

const useFeedloopService = () => {
  const API_URL = process.env.API_URL;
  const S3_URL = process.env.S3_URL;
  const { state } = React.useContext<GlobalStore>(Store);
  const getDirectories = async (projectGuid: string) => {
    const { data } = await axios.get(`${API_URL}/v1/projects/${projectGuid}/directories`, {
      headers: {
        Authorization: `Bearer ${state.auth.token}`,
      },
      validateStatus: status => {
        return status >= 200 && status < 500;
      },
    });
    return data.data;
  };
  const createCampaign = async (projectGuid: string, data: APICampaign) => {
    return await axios.post(`${API_URL}/v1/projects/${projectGuid}/campaigns`, data, {
      headers: {
        Authorization: `Bearer ${state.auth.token}`,
      },
      validateStatus: status => {
        return status >= 200 && status < 500;
      },
    });
  };
  const publishCampaign = async (
    projectGuid: string,
    campaignGuid: string,
    apiCampaign: APICampaign
  ) => {
    const resourcePath = `${process.env.API_URL ||
      ''}/v1/projects/${projectGuid}/campaigns/${campaignGuid}`;
    const historyPath = `${resourcePath}/campaign-histories`;
    const {
      data: {
        data: { campaign: updatedCampaign },
      },
    } = await axios.patch<{ data: { campaign: APICampaign } }>(
      resourcePath,
      apiCampaign.type === 'legacy' && apiCampaign.schema
        ? CampaignMixer.mix(apiCampaign, apiCampaign.schema)
        : apiCampaign,
      {
        headers: { Authorization: `Bearer ${state.auth.token}` },
      }
    );
    if (updatedCampaign && updatedCampaign.schema && !!Object.keys(updatedCampaign.schema).length) {
      if (updatedCampaign.type === 'legacy') {
        updatedCampaign.schema.variables = [
          ...updatedCampaign.schema.variables,
          ...updatedCampaign.campaignVariables.map<[string, Variable]>(v => [
            v.namespace,
            { ...v, guid: v.guid || '' },
          ]),
        ];
      }

      await axios.post<{ data: { campaignHistoryGuid: string } }>(
        historyPath,
        { campaignData: updatedCampaign.schema },
        { headers: { Authorization: `Bearer ${state.auth.token}` } }
      );
    }
  };
  const saveCampaignAsTemplate = (
    projectGuid: string,
    { id, name, lastMigration, ...rest }: Campaign,
    title: string,
    description: string
  ) => {
    const resourcePath = `${process.env.API_URL ||
      ''}/v1/projects/${projectGuid}/campaign-templates`;
    return axios.post<{ data: { templateGuid: string } }>(
      resourcePath,
      { name: title, description, data: rest },
      { headers: { Authorization: `Bearer ${state.auth.token}` } }
    );
  };
  const getHistories = async (projectGuid: string, campaignGuid: string) => {
    return await axios.get(
      `${API_URL}/v1/projects/${projectGuid}/campaigns/${campaignGuid}/campaign-histories`,
      {
        headers: {
          Authorization: `Bearer ${state.auth.token}`,
          'Content-Type': 'application/json',
        },
        validateStatus: status => {
          return status >= 200 && status < 500;
        },
      }
    );
  };
  const duplicateCampaign = async (projectGuid: string, data: APICampaign): Promise<boolean> => {
    if (!state.auth || !state.auth.token) return false;
    const getMicrositeDataResponse = await getHistories(projectGuid, data.guid);
    if (getMicrositeDataResponse.status !== 200) return false;
    const histories = getMicrositeDataResponse.data.data.campaignHistories;
    const latestHistory: Campaign = histories.length
      ? histories.reduce((prev: any, curr: any) => {
          return prev.createdAt > curr.createdAt ? prev : curr;
        }).campaignData
      : data.schema;
    const createCampaignResponse = await createCampaign(
      projectGuid,
      CampaignMixer.mix(
        {
          ...data,
          name: `Copy of ${data.name}`,
          status: APICampaignStatus.ACTIVE,
          subdomain: generateSubdomain(16),
        },
        latestHistory
      )
    );
    return createCampaignResponse.status === 200;
  };
  const processResponses = (response: AxiosResponse<APICampaignResponse>): CampaignResponses => {
    let questions: string[] = [];
    let sessionGuids: string[] = [];
    let answers: Map<string, string[][]> = new Map();
    let pageResults: Map<string, string> = new Map();

    response.data.data.results.forEach(result => {
      if (!sessionGuids.find(s => s === result.sessionGuid))
        sessionGuids = [...sessionGuids, result.sessionGuid];
      let sessionAnswers: string[][] = [];
      result.answers.forEach((content, i) => {
        if (questions.length <= i) questions = [...questions, content.attributes.title];
        sessionAnswers = [...sessionAnswers, content.answer];
      });
      answers.set(result.sessionGuid, sessionAnswers);
      pageResults.set(result.sessionGuid, result.pageResult);
    });
    return {
      total: parseInt(response.data.data.total, 10),
      questions,
      sessionGuids,
      answers,
      pageResults,
    };
  };
  const getAllResponses = async (
    projectGuid: string,
    campaignGuid: string,
    offset: number = 0,
    limit: number = 10
  ): Promise<[boolean, CampaignResponses | null]> => {
    if (!state.auth || !state.auth.token) return [false, null];
    const response = await axios.get(
      `${API_URL}/v1/projects/${projectGuid}/campaigns/${campaignGuid}/events`,
      {
        params: {
          offset,
          limit,
        },
        headers: {
          Authorization: `Bearer ${state.auth.token}`,
          'Cache-Control': 'no-cache',
        },
        validateStatus: status => {
          return status >= 200 && status < 500;
        },
      }
    );
    if (response.status !== 200) return [false, null];
    return [true, processResponses(response)];
  };
  const downloadResponses = async (
    projectGuid: string,
    campaignGuid: string,
    type: string
  ): Promise<Blob | null> => {
    if (!state.auth || !state.auth.token) return null;
    const response = await axios.get(
      `${API_URL}/v1/projects/${projectGuid}/campaigns/${campaignGuid}/download-events?type=${type}`,
      {
        responseType: 'blob',
        headers: {
          Authorization: `Bearer ${state.auth.token}`,
          'Cache-Control': 'no-cache',
        },
        validateStatus: status => {
          return status >= 200 && status < 500;
        },
      }
    );
    if (response.status !== 200) return null;
    return new Blob([response.data]);
  };
  const downloadMetrics = async (params: {
    projectGuid: string;
    campaignGuid: string;
    metricGuid: string;
    start: string;
    end: string;
    groupBy?: string;
    type: string;
  }): Promise<Blob | null> => {
    if (!state.auth || !state.auth.token) return null;
    const { start, end, groupBy, type } = params;
    const response = await axios.get(
      `${API_URL}/v1/projects/${params.projectGuid}/campaigns/${params.campaignGuid}/metrics/${params.metricGuid}/download?groupBy=${groupBy}&start=${start}&end=${end}&type=${type}`,
      {
        headers: {
          Authorization: `Bearer ${state.auth.token}`,
          'Cache-Control': 'no-cache',
        },
        responseType: 'arraybuffer',
        validateStatus: status => {
          return status >= 200 && status < 500;
        },
      }
    );
    if (response.status !== 200) return null;
    return new Blob([response.data]);
  };
  const getGlobalCampaignTemplate = async (type: 'legacy' | 'kanvas') => {
    const { data } = await axios.get<APICampaignTemplateResponse>(
      `${API_URL}/v1/campaign-templates?type=${type}`,
      {
        headers: {
          Authorization: `Bearer ${state.auth.token}`,
        },
        validateStatus: status => {
          return status >= 200 && status < 500;
        },
      }
    );
    return data.data;
  };
  return {
    publishCampaign,
    saveCampaignAsTemplate,
    getDirectories,
    duplicateCampaign,
    getAllResponses,
    downloadResponses,
    downloadMetrics,
    getGlobalCampaignTemplate,
  };
};

export default useFeedloopService;
