import { Reducer, AppState } from '../interfaces/store';
import { IAction } from '../interfaces/action';
import { APIProject } from '../interfaces/project';
import { APICampaign } from '../interfaces/campaign';

export const handleGetProjectsType: Reducer = (state: AppState, _: IAction): AppState => ({
  ...state,
  project: {
    ...state.project,
    getLoading: true,
    getError: false,
    projects: undefined,
    current: {} as APIProject,
  },
});

export const handleGetProjectsSuccessType: Reducer = (
  state: AppState,
  action: IAction
): AppState => ({
  ...state,
  project: {
    ...state.project,
    getLoading: false,
    getError: false,
    projects: action.payload,
  },
});

export const handleGetProjectsFailedType: Reducer = (state: AppState, _: IAction): AppState => ({
  ...state,
  project: {
    ...state.project,
    getLoading: false,
    getError: true,
    projects: [],
  },
});

export const handleCreateProjectType: Reducer = (state: AppState, _: IAction): AppState => ({
  ...state,
  project: {
    ...state.project,
    createLoading: true,
    createError: false,
  },
});

export const handleCreateProjectSuccessType: Reducer = (state: AppState, _: IAction): AppState => ({
  ...state,
  project: {
    ...state.project,
    createLoading: false,
    createError: false,
    projects: undefined,
  },
});

export const handleCreateProjectFailedType: Reducer = (state: AppState, _: IAction): AppState => ({
  ...state,
  project: {
    ...state.project,
    createLoading: false,
    createError: true,
  },
});

export const handleSelectProjectType: Reducer = (state: AppState, action: IAction): AppState => ({
  ...state,
  project: {
    ...state.project,
    current: action.payload,
  },
});

export const handleResetProjectCampaignType: Reducer = (state: AppState, _: IAction): AppState => ({
  ...state,
  project: {
    ...state.project,
    current: undefined,
    projects: undefined,
  },
  campaign: {
    ...state.campaign,
    current: undefined,
    campaigns: new Map<string, APICampaign[]>(),
  },
});
