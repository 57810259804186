import { Campaign, ContentPresetKind, parser } from '@feedloop/template-engine';
import {
  APICampaign,
  APICampaignGlobalVariable,
  APICampaignVariableType,
  APIWebContent,
  APICampaignVariable,
} from '../interfaces/campaign';
import { APIContent, APIContentType } from '../interfaces/content';
import v5 from 'uuid/v5';
import {
  getOptionsWithFallback,
  getOptionsImageWithFallback,
} from '@feedloop/template-engine/lib/presets/utils';

const EVENT_NAMESPACE = '1b671a64-40d5-491e-99b0-da01ff1f3341';

export default class CampaignMixer {
  static mix(existing: APICampaign, json: Campaign): APICampaign {
    let globalVariables: APICampaignGlobalVariable[] = [];
    let campaignVariables: APICampaignVariable[] = [];
    const copyofJson = { ...json };
    copyofJson.variables.forEach(([key, v]) => {
      switch (v.type) {
        case 'global':
          // tslint:disable-next-line: no-array-mutation
          globalVariables.push({
            name: key,
            dataType:
              v.valueType === 'number'
                ? APICampaignVariableType.NUMERIC
                : APICampaignVariableType.STRING,
            defaultValue: v.defaultValue,
            value: v.value.toString(),
            referenceId: v.referenceId,
          });
          break;
        case 'userGenerated':
        case 'session':
          // tslint:disable-next-line: no-array-mutation
          campaignVariables.push({
            name: v.name || key,
            valueType: v.valueType,
            type: 'userGenerated',
            namespace: key,
          });
          break;
        default:
          break;
      }
    });
    const existingContents = new Map(
      existing.contents.map(v => {
        return [v.contentGuid, v];
      })
    );
    const webContents: APIWebContent[] = [
      ...pageToWebContent(copyofJson, 'mainPages', existingContents),
      ...pageToWebContent(copyofJson, 'inactivePages'),
      ...pageToWebContent(copyofJson, 'results'),
    ];

    // TODO: contents should not be replaced fully, it should be full compare
    // please revisit
    const campaign: APICampaign = {
      ...existing,
      webContents,
      globalVariables,
      sessionVariables: [],
      campaignVariables,
      deliveryType: 'microsite',
      inPanel: false,
      index: 0,
      persist: true,
      trigger: {
        pages: ['*'],
        triggerType: 'pageload',
      },
      pages: copyofJson,
      schema: copyofJson,
      type: 'legacy',
    };
    delete campaign.slug;
    return campaign;
  }
}

const pageToWebContent = (
  json: Campaign,
  section: 'mainPages' | 'results' | 'inactivePages',
  existingContents?: Map<string, APIContent>
) => {
  const webContents: APIWebContent[] = [];
  (json[section] || []).forEach((page, pageOrder) => {
    page.contents.forEach((content, order) => {
      const webContent: APIWebContent = {
        guid: content.id,
        pageName: section,
        pageOrder,
        order,
        schema: content,
      };
      if (section === 'results' && order === 0) {
        webContent.content = {
          contentType: APIContentType.HIDDEN,
          tag: 'hidden',
          attributes: {
            name: page.name || 'untitled',
            referenceId: page.pageGuid,
          },
        };
      }
      if (!existingContents) {
        webContents.push(webContent);
        return;
      }
      const fieldsMap = new Map(content.fieldsMap);
      // TODO: outcomes should be tranpiled into variables
      // please revisit
      // const outcomes = new Map(content.outcomes);

      const rocketContent: APIContent | undefined = existingContents.get(content.id);
      switch (content.preset.name) {
        case ContentPresetKind.NumberSlider:
          const range = parser['number[]'](fieldsMap.get('range'));
          // tslint:disable-next-line: no-array-mutation
          webContent.content = {
            contentType: APIContentType.NUMBER_SLIDER,
            tag: content.name,
            attributes: {
              title: fieldsMap.get('question') || '',
              text: '',
              visual: 'number',
              min: parser['number'](range[0]),
              max: parser['number'](range[1]),
            },
          };
          break;
        case ContentPresetKind.NPS:
          // tslint:disable-next-line: no-array-mutation
          webContent.content = {
            contentType: APIContentType.NPS,
            tag: content.name,
            attributes: {
              title: fieldsMap.get('question') || '',
              choices: [],
            },
          };
          break;
        case ContentPresetKind.MultipleSelect:
        case ContentPresetKind.MultipleChoice:
        case ContentPresetKind.ImageChoice:
        case ContentPresetKind.Dropdown:
          let choices;
          switch (content.preset.name) {
            case ContentPresetKind.ImageChoice:
              choices = getOptionsImageWithFallback(
                fieldsMap.get('options'),
                fieldsMap.get('images'),
                content.id
              ).options.map(opt => ({
                label: opt.placeholder,
                referenceId: opt.id,
              }));
              break;
            default:
              choices = getOptionsWithFallback(fieldsMap.get('options'), content.id).options.map(
                opt => ({
                  label: opt.value,
                  referenceId: opt.id,
                })
              );
              break;
          }
          switch (content.preset.name) {
            case ContentPresetKind.ImageChoice:
            case ContentPresetKind.MultipleSelect:
            case ContentPresetKind.MultipleChoice:
              // tslint:disable-next-line: no-array-mutation
              webContent.content = {
                contentType: APIContentType.MULTIPLE_CHOICE,
                tag: content.name,
                attributes: {
                  title: fieldsMap.get('question'),
                  text: '',
                  choices: choices,
                },
              };
              break;
            case ContentPresetKind.Dropdown:
              // tslint:disable-next-line: no-array-mutation
              webContent.content = {
                contentType: APIContentType.DROPDOWN,
                tag: content.name,
                attributes: {
                  title: fieldsMap.get('question'),
                  choices: choices,
                },
              };
              break;
            default:
              break;
          }
          break;
        case ContentPresetKind.Conversation:
          const existingAnswers = rocketContent ? rocketContent.attributes.choices : [];
          let answerReferenceIds: string[] = existingAnswers.map((v: any) => {
            return v.referenceId;
          });
          const answers = getOptionsWithFallback(fieldsMap.get('answers'), content.id).options.map(
            (option, idx) => ({
              label: option.value,
              referenceId:
                answerReferenceIds[idx] ||
                v5(`${content.id}:${idx + 1}:${option.value}`, EVENT_NAMESPACE),
            })
          );
          // tslint:disable-next-line: no-array-mutation
          webContent.content = {
            contentType: APIContentType.MULTIPLE_CHOICE,
            tag: content.name,
            attributes: {
              title: getOptionsWithFallback(fieldsMap.get('messages'))
                .options.map(option => option.value)
                .join(', '),
              choices: answers,
            },
          };
          break;
        case ContentPresetKind.Datepicker:
        case ContentPresetKind.FreeText:
          // tslint:disable-next-line: no-array-mutation
          webContent.content = {
            contentType: APIContentType.FREETEXT_INPUT,
            tag: content.name,
            attributes: {
              text: '',
              title: fieldsMap.get('question'),
              validator: 'regex',
              regex: '',
              buttonLabel: fieldsMap.get('submitLabel') || 'Submit',
            },
          };
          break;
        case ContentPresetKind.PersonalIdentity:
          // tslint:disable-next-line: no-array-mutation
          webContent.content = {
            contentType: APIContentType.FREETEXT_INPUT,
            tag: content.name,
            attributes: {
              text: '',
              title: fieldsMap.get('question'),
              validator: 'email',
              regex: '',
              buttonLabel: fieldsMap.get('submitLabel') || 'Submit',
            },
          };
          break;
        default:
          break;
      }
      webContents.push(webContent);
    });
  });
  return webContents;
};
