import { AppState } from '../interfaces/store';
import { APICampaign } from '../interfaces/campaign';
import dayjs from '../utils/dayjs';

const getInitialStartDate = () =>
  dayjs()
    .startOf('month')
    .format('DD-MM-YYYY');
const getInitialEndDate = () =>
  dayjs()
    .add(1, 'day')
    .format('DD-MM-YYYY');
const getInitialCompareStartDate = () => {
  const start = getInitialStartDate();
  const diffs = dayjs(getInitialEndDate(), 'DD-MM-YYYY').diff(dayjs(start, 'DD-MM-YYYY'), 'day');
  return dayjs(start, 'DD-MM-YYYY')
    .subtract(diffs, 'day')
    .format('DD-MM-YYYY');
};

const initialState: AppState = {
  auth: {
    loaded: false,
    loading: false,
    error: false,
    token: '',
  },
  store: {
    auth: {
      loaded: false,
      loading: false,
      error: false,
      message: '',
      token: '',
    },
    guids: {
      slug: '',
      token: '',
      locationDirectoryGuid: '',
      projectGuid: '',
      campaignGuid: '',
      metricGuid: '',
    },
  },
  register: {
    registered: false,
    loading: false,
    createError: false,
  },
  project: {
    getLoading: false,
    getError: false,
    projects: undefined,
    current: undefined,
    createLoading: false,
    createError: false,
  },
  filter: {
    dashboard: {
      overview: {
        rx: 'day',
        ix: 'day',
      },
      store: {
        detail: 'month',
      },
    },
  },
  filterDate: {
    dashboard: {
      overview: {
        rx: {
          start: getInitialStartDate(),
          end: getInitialEndDate(),
          compareStart: getInitialCompareStartDate(),
          compareEnd: getInitialStartDate(),
        },
      },
      store: {
        detail: {
          start: getInitialStartDate(),
          end: getInitialEndDate(),
          compareStart: getInitialCompareStartDate(),
          compareEnd: getInitialStartDate(),
        },
      },
    },
  },
  campaign: {
    getLoading: false,
    getError: false,
    campaigns: new Map<string, APICampaign[]>(),
    current: undefined,
    createLoading: false,
    createError: false,
    updateLoading: false,
    updateError: false,
    keyStatisticsLoading: false,
    keyStatisticsError: false,
    keyStatistics: [],
    contentStatisticsLoading: false,
    contentStatisticsError: false,
    contentStatistics: [],
  },
  editor: {
    section: 'main',
    page: 0,
    content: 0,
  },
};

export default initialState;
