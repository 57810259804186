import { Reducer, AppState } from '../interfaces/store';
import { IAction } from '../interfaces/action';

export const handlePostStoreLoginType: Reducer = (state: AppState, _: IAction): AppState => ({
  ...state,
  store: { ...state.store, auth: { ...state.store.auth, loading: true, error: false, token: '' } },
});

export const handlePostStoreLoginSuccessType: Reducer = (
  state: AppState,
  action: IAction
): AppState => ({
  ...state,
  store: {
    ...state.store,
    auth: { ...state.store.auth, loading: false, error: false, token: action.payload.token },
    guids: {
      ...state.store.guids,
      ...action.payload,
    },
  },
});

export const handlePostStoreLoginFailedType: Reducer = (
  state: AppState,
  action: IAction
): AppState => ({
  ...state,
  store: {
    ...state.store,
    auth: {
      ...state.store.auth,
      loading: false,
      error: action.payload.error,
      message: action.payload.errorMessage,
    },
  },
});

export const handleLoadStoreTokenType: Reducer = (state: AppState, _: IAction): AppState => ({
  ...state,
  store: { ...state.store, auth: { ...state.store.auth, loading: true, error: false, token: '' } },
});

export const handleLoadStoreTokenSuccessType: Reducer = (
  state: AppState,
  action: IAction
): AppState => ({
  ...state,
  store: {
    ...state.store,
    auth: {
      ...state.store.auth,
      loaded: true,
      loading: false,
      error: false,
      token: action.payload,
    },
  },
});

export const handleLoadStoreTokenFailedType: Reducer = (state: AppState, _: IAction): AppState => ({
  ...state,
  store: {
    ...state.store,
    auth: { ...state.store.auth, loaded: true, loading: false, error: true },
  },
});

export const handleStoreLogoutType: Reducer = (state: AppState, _: IAction): AppState => ({
  ...state,
  store: { ...state.store, auth: { ...state.store.auth, loaded: false, token: '' } },
});

export const handleLoadStoreDataType: Reducer = (state: AppState, action: IAction): AppState => ({
  ...state,
  store: {
    ...state.store,
    guids: {
      campaignGuid: '',
      locationDirectoryGuid: '',
      metricGuid: '',
      projectGuid: '',
      slug: '',
      token: '',
    },
  },
});

export const handleLoadStoreDataSuccessType: Reducer = (
  state: AppState,
  action: IAction
): AppState => ({
  ...state,
  store: {
    ...state.store,
    guids: {
      ...action.payload,
    },
  },
});

export const handleLoadStoreDataFailedType: Reducer = (
  state: AppState,
  action: IAction
): AppState => ({
  ...state,
  store: {
    ...state.store,
    guids: {
      campaignGuid: '',
      locationDirectoryGuid: '',
      metricGuid: '',
      projectGuid: '',
      slug: '',
      token: '',
    },
  },
});
