import React from 'react';

import BuilderProvider from './campaign/BuilderProvider';
import AuthProvider from './AuthProvider';
import ProjectManagerProvider from './ProjectManagerProvider';

const FeedloopContextProvider = ({ children }: { children: React.ReactNode }) => (
  <AuthProvider>
    <ProjectManagerProvider>
      <BuilderProvider>{children}</BuilderProvider>
    </ProjectManagerProvider>
  </AuthProvider>
);

export default FeedloopContextProvider;
