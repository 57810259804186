import React from 'react';
import { Router, Switch, Route } from 'react-router-dom';
import { createBrowserHistory, History } from 'history';
import ApolloClient from 'apollo-boost';
import { ApolloProvider } from '@apollo/react-hooks';
import Loading from './app/common/Loading';
import { StoreProvider } from './store';
import ErrorBoundary from './app/error/ErrorBoundary';

import FeedloopV2ContextProvider from './app/FeedloopContextProvider';
import ThemeProvider, { ThemeContext } from './app/common/ThemeProvider';
import NewThemeWrapper from './app/common/NewTheme/NewThemeWrapper';
import Storage from './utils/storage';

const history: History = createBrowserHistory();

const LoginPage = React.lazy(() =>
  import(/* webpackChunkName: "LoginPage", webpackPrefetch: true */ './app/LoginPage')
);
const RegisterPage = React.lazy(() =>
  import(/* webpackChunkName: "RegisterPage", webpackPrefetch: true */ './app/RegisterPage')
);
const AuthPage = React.lazy(() =>
  import(/* webpackChunkName: "RegisterPage", webpackPrefetch: true */ './app/AuthPage')
);
const LoggedInPage = React.lazy(() =>
  import(/* webpackChunkName: "LoggedInPage", webpackPrefetch: true */ './app/LoggedInPage')
);
const LoggedInStorePage = React.lazy(() =>
  import(
    /* webpackChunkName: "LoggedInStorePage", webpackPrefetch: true */ './app/LoggedInStorePage'
  )
);

const client = new ApolloClient({
  uri: process.env.GRAPHQL_ENDPOINT,
  request: ctx => {
    const token = Storage.load('token');
    ctx.setContext({
      headers: {
        Authorization: `Bearer ${token?.token}`,
      },
    });
  },
});

const App = () => {
  const { LegacyThemeWrapper } = React.useContext(ThemeContext);
  return (
    <ErrorBoundary>
      <ApolloProvider client={client}>
        <StoreProvider>
          <FeedloopV2ContextProvider>
            <ThemeProvider>
              <Router history={history}>
                <React.Suspense fallback={Loading}>
                  <Switch>
                    <Route
                      exact
                      path="/login"
                      render={() => (
                        <LegacyThemeWrapper>
                          <LoginPage />
                        </LegacyThemeWrapper>
                      )}
                    />
                    <Route
                      exact
                      path="/register"
                      render={() => (
                        <LegacyThemeWrapper>
                          <RegisterPage />
                        </LegacyThemeWrapper>
                      )}
                    />
                    <Route
                      exact
                      path="/auth/:type"
                      render={() => (
                        <NewThemeWrapper>
                          <AuthPage />
                        </NewThemeWrapper>
                      )}
                    />
                    <Route
                      path="/stores/:slug"
                      render={() => (
                        <LegacyThemeWrapper>
                          <LoggedInStorePage />
                        </LegacyThemeWrapper>
                      )}
                    />
                    <Route path="/" component={LoggedInPage} />
                  </Switch>
                </React.Suspense>
              </Router>
            </ThemeProvider>
          </FeedloopV2ContextProvider>
        </StoreProvider>
      </ApolloProvider>
    </ErrorBoundary>
  );
};

export default App;
