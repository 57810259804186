import { Map } from 'immutable';
import Lazy from 'lazy.js';

import { TemplateLabel, Content, Page, TemplateConfig } from '@feedloop/template-engine';
import { Cursor } from '@feedloop/template-engine';
import { LabelValues } from '@feedloop/template-engine/lib/campaign';

const mapDefaultValue = (labels: TemplateLabel[], defaultSettings?: LabelValues) => {
  if (!defaultSettings) return labels;

  return labels.map(label => {
    const defaultValue = defaultSettings[label.name];
    if (typeof defaultValue === 'string') {
      return {
        ...label,
        defaultValue,
      };
    }

    return label;
  });
};

type UnsupportedCursor = [string, Cursor];

const collectUnsupported = (
  pages: Page[],
  section: 'main' | 'result' | 'inactive',
  templatesMap: Map<string, TemplateConfig>
) => {
  const unsupported = Lazy(pages)
    .map<UnsupportedCursor[]>(page => {
      const layoutConfig = templatesMap.get(page.preset.name);
      if (!layoutConfig) return [];
      const allowedContentTypesSet = new Set(layoutConfig.contentPresetKinds || []);
      const unsupportedCursor = Lazy(page.contents)
        .filter(
          (content: Content) =>
            !templatesMap.get(content.preset.name) ||
            (allowedContentTypesSet.size > 0 && !allowedContentTypesSet.has(content.preset.name))
        )
        .map<UnsupportedCursor>((content: Content) => {
          const cursor: Cursor = {
            content: page.contents.indexOf(content),
            page: pages.indexOf(page),
            restartCount: 0,
            section,
          };
          return [content.name, cursor];
        })
        .toArray();
      return unsupportedCursor;
    })
    .reduce<UnsupportedCursor[]>((prev, curr) => [...prev, ...curr], []);
  return unsupported;
};

export default {
  mapDefaultValue,
  collectUnsupported,
};
