import React from 'react';
import dotenv from 'dotenv';
import * as Sentry from '@sentry/browser';

dotenv.config();

interface ErrorBoundaryProps {
  children: React.ReactNode;
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps> {
  componentDidCatch(error: any, _: any) {
    if (
      process.env.ENVIRONMENT &&
      (process.env.ENVIRONMENT === 'staging' || process.env.ENVIRONMENT === 'production')
    ) {
      Sentry.captureException(error);
    } else {
      console.error(error);
    }
  }

  render() {
    return this.props.children;
  }
}

export default React.memo(ErrorBoundary);
