import { Reducer, AppState } from '../interfaces/store';
import { IAction } from '../interfaces/action';

export const handlePostRegisterType: Reducer = (state: AppState, _: IAction): AppState => ({
  ...state,
  register: {
    ...state.register,
    loading: true,
    registered: true,
    createError: false,
  },
});

export const handlePostRegisterSuccessType: Reducer = (state: AppState, _: IAction): AppState => ({
  ...state,
  register: {
    ...state.register,
    loading: false,
    registered: true,
    createError: false,
  },
});

export const handlePostRegisterFailedType: Reducer = (state: AppState, _: IAction): AppState => ({
  ...state,
  register: {
    ...state.register,
    loading: false,
    registered: true,
    createError: true,
  },
});
