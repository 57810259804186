import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import toArray from 'dayjs/plugin/toArray';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(relativeTime);
dayjs.extend(toArray);
dayjs.extend(customParseFormat);

export default dayjs;
