import FeedloopService from '../services/feedloop.service';
import S3Service from '../services/s3.service';
import { migrateNewCampaign } from '@feedloop/template-engine/lib';
import { Campaign } from '@feedloop/template-engine';
import { APICampaign, APICampaignGlobalVariable } from '../interfaces/campaign';
import CampaignMixer from '../utils/campaignMixer';

export const getCampaign = async (subdomain: string) => {
  try {
    const response = await new FeedloopService().getCampaignGuidBySubdomain(subdomain);
    const campaign = await new FeedloopService().getCampaignByAPIKey(
      response.apiKey,
      response.campaignGuid
    );
    return campaign;
  } catch (err) {
    throw err;
  }
};

/*
# GET OR MIGRATE CAMPAIGN
Since July 2019, we upgrade our template engine into reusable javascript library.
Therefore, old microsite data structure should be migrated into the new one.
We are using migration mechanism from newest template engine.

Author: Krisna Fathurahman
Last updated: 16/07/2019 18:31 UTC+7
*/
function getVariableValue(name: string, variables: APICampaignGlobalVariable[]): any {
  const v = variables.find(v => v.name === name);
  if (!v) throw Error('Cannot find global variable ' + name);
  return v.dataType === 'numeric' ? parseInt(v.value, 10) : v.value;
}
export const getOrMigrateCampaign = async (
  token: string,
  projectGuid: string,
  subdomain: string
) => {
  try {
    const response = await new FeedloopService().getCampaignGuidBySubdomain(subdomain);
    const campaignBackend = await new FeedloopService().getCampaignByAPIKey(
      response.apiKey,
      response.campaignGuid
    );
    let campaignData;
    if (campaignBackend.schema && !!Object.keys(campaignBackend.schema).length) {
      campaignData = campaignBackend.schema;
    } else {
      campaignData = await new S3Service().getMicrositeData(response.campaignGuid);
    }
    if (!campaignData) {
      // should migrated
      const migrated = await migrateNewCampaign(subdomain);
      await new FeedloopService().saveCampaignHistory(
        token,
        projectGuid,
        response.campaignGuid,
        migrated
      );
      const storageUrl = await new FeedloopService().getS3JsonUrl(
        token,
        projectGuid,
        response.campaignGuid,
        'campaign',
        '.json'
      );
      await new S3Service().uploadCampaignJSON(storageUrl, migrated);
      return migrated;
    }
    const campaigns = await new FeedloopService().getCampaignHistory(
      token,
      projectGuid,
      response.campaignGuid
    );
    if (campaigns.length > 0) {
      return campaigns.reduce((prev, curr) => {
        return prev.createdAt > curr.createdAt ? prev : curr;
      }).campaignData;
    }
    if ('name' in campaignData) {
      campaignData.variables.map(([key, v]) => {
        if (v.type === 'global')
          v.value = getVariableValue(key, campaignBackend.globalVariables) || v.value;
        return [key, v];
      });
    }

    return campaignData;
  } catch (err) {
    throw err;
  }
};

export const saveCampaign = async (
  token: string,
  projectGuid: string,
  subdomain: string,
  campaign: Campaign
) => {
  try {
    const response = await new FeedloopService().getCampaignGuidBySubdomain(subdomain);
    return await new FeedloopService().saveCampaignHistory(
      token,
      projectGuid,
      response.campaignGuid,
      campaign
    );
  } catch (err) {
    throw err;
  }
};

export const getKeyAnalytics = async (token: string, projectGuid: string, campaignGuid: string) => {
  try {
    const response = await new FeedloopService().getCampaignKeyAnalytics(
      token,
      projectGuid,
      campaignGuid
    );
    return response;
  } catch (err) {
    throw err;
  }
};

export const getContentAnalytics = async (
  token: string,
  projectGuid: string,
  campaignGuid: string
) => {
  try {
    const response = await new FeedloopService().getCampaignContentAnalytics(
      token,
      projectGuid,
      campaignGuid
    );
    return response;
  } catch (err) {
    throw err;
  }
};

export const changeCampaignStatus = async (
  token: string,
  projectGuid: string,
  campaignGuid: string,
  campaign: APICampaign
) => {
  const response = await new FeedloopService().updateCampaign(
    token,
    projectGuid,
    campaignGuid,
    campaign
  );
  return response;
};
