import React from 'react';

const LegacyThemeWrapper = React.lazy(() =>
  import(/* webpackChunkName: "LegacyThemeWrapper", webpackPrefetch: true */ './LegacyThemeWrapper')
);

const NewThemeWrapper = React.lazy(() =>
  import(
    /* webpackChunkName: "NewThemeWrapper", webpackPrefetch: true */ './NewTheme/NewThemeWrapper'
  )
);

export const ThemeContext = React.createContext({
  LegacyThemeWrapper,
  NewThemeWrapper,
});

export const useTheme = () => {
  return { LegacyThemeWrapper, NewThemeWrapper };
};

const ThemeProvider = (props: { children: React.ReactNode }) => {
  const ls = window.localStorage;
  !ls.getItem('theme') && ls.setItem('theme', 'legacy');
  const context = useTheme();
  return <ThemeContext.Provider value={{ ...context }}>{props.children}</ThemeContext.Provider>;
};

export default ThemeProvider;
