export default class Storage {
  static load(key: string): any {
    if (!window.localStorage) return null;
    const dataString = localStorage.getItem(key);
    if (!dataString) return null;
    const record = JSON.parse(dataString);
    if (!record || new Date().getTime() > record.timestamp) return null;
    return JSON.parse(record.value);
  }

  static save(key: string, data: any, expirationMin?: number): boolean {
    if (!window.localStorage) return false;
    const expirationMS = expirationMin ? expirationMin * 60 * 1000 : 3600 * 60 * 1000;
    const record = {
      value: JSON.stringify(data),
      timestamp: new Date().getTime() + expirationMS,
    };
    localStorage.setItem(key, JSON.stringify(record));
    return true;
  }

  static remove(key: string): boolean {
    if (!window.localStorage) return false;
    localStorage.removeItem(key);
    return true;
  }
}
