import * as React from 'react';
import CampaignBuilder from '@feedloop/template-engine/lib/builder/CampaignBuilder';
import { APIProject, APIProjectStatus } from '../../interfaces/project';

const initialBuilder = new CampaignBuilder();
const initialProject: APIProject = {
  id: 0,
  guid: '',
  userId: 0,
  title: '',
  type: '',
  description: '',
  logo: '',
  status: APIProjectStatus.INACTIVE,
  campaigns: [],
};
const BuilderContext = React.createContext({
  setBuilder: (builder: CampaignBuilder) => {},
  builder: initialBuilder,
  project: initialProject,
  setProject: (project: APIProject) => {},
});

const { Provider } = BuilderContext;

const BuilderProvider = (props: { children: React.ReactNode }) => {
  const [builder, setBuilder] = React.useState(initialBuilder);
  const [project, setProject] = React.useState<APIProject>(initialProject);
  const value = { setBuilder, builder, project, setProject };
  return <Provider value={value}>{props.children}</Provider>;
};

export const useBuilderContext = () => {
  return React.useContext(BuilderContext);
};

export const useCampaign = () => {
  const { builder } = useBuilderContext();
  const campaign = React.useMemo(() => builder.toCampaign(), [builder]);
  return campaign;
};

export default BuilderProvider;
