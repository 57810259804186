import axios from 'axios';
import Storage from '../utils/storage';

type SigninResponse = {
  error: boolean;
  errorMessage?: string;
  payload?: string;
};
const encodeForm = (data: any) => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
};

export default class AuthService {
  apiUrl: string;

  constructor() {
    this.apiUrl = process.env.API_URL || 'http://localhost:3000';
  }

  async signin(email: string, password: string): Promise<SigninResponse> {
    try {
      const response = await axios.post(
        `${this.apiUrl}/v1/sessions/sign-in`,
        { email, password },
        {
          validateStatus: status => {
            return status >= 200 && status < 500;
          },
        }
      );
      if (
        response.status !== 200 ||
        !Storage.save('token', { token: response.data.data.token }, 1209600)
      )
        throw new Error('Failed to save token');
      return Promise.resolve({
        error: false,
        payload: response.data.data.token,
      });
    } catch (err) {
      return Promise.reject({
        error: true,
        errorMessage: 'Invalid email & password combination',
      });
    }
  }
  async storeSignin(slug: string, password: string): Promise<SigninResponse> {
    try {
      const response = await axios.post(
        `${this.apiUrl}/v1/sessions/stores/sign-in`,
        { slug, password },
        {
          validateStatus: status => {
            return status >= 200 && status < 500;
          },
        }
      );
      if (response.status !== 200) throw new Error(response.data.errors[0]);
      if (!Storage.save('storeToken', { token: response.data.data.token }, 1209600))
        throw new Error('Failed to save store token');
      const guids = { ...response.data.data, slug };
      const stores = JSON.parse(Storage.load('stores') || '{}');
      if (!Storage.save('stores', JSON.stringify({ ...stores, [slug]: guids }), 1209600))
        throw new Error('Failed to save store data');
      return Promise.resolve({
        error: false,
        payload: guids,
      });
    } catch (err) {
      return Promise.reject({
        error: true,
        errorMessage: err.message,
      });
    }
  }
  async signup(
    email: string,
    password: string,
    fullname: string,
    phoneNumber: string,
    companyName: string,
    companySize: number
  ): Promise<SigninResponse> {
    try {
      const response = await axios.post(
        `${this.apiUrl}/v1/sessions/register`,
        encodeForm({ email, password, fullname, phoneNumber, companyName, companySize }),
        {
          validateStatus: status => {
            return status >= 200 && status < 500;
          },
        }
      );
      if (
        response.status !== 200 ||
        !Storage.save('token', { token: response.data.data.token }, 1209600)
      )
        throw new Error('Failed to save token');
      return Promise.resolve({
        error: false,
        payload: response.data.data.token,
      });
    } catch (err) {
      return Promise.reject({
        error: true,
        errorMessage: 'Invalid email combination',
      });
    }
  }
}
