import { Reducer, AppState } from '../interfaces/store';
import { IAction } from '../interfaces/action';

export const handleChangeFilterOverviewDashboardRxType: Reducer = (
  state: AppState,
  action: IAction
): AppState => ({
  ...state,
  filter: {
    ...state.filter,
    dashboard: {
      ...state.filter.dashboard,
      overview: {
        ...state.filter.dashboard.overview,
        rx: action.payload,
      },
    },
  },
});

export const handleChangeFilterDateOverviewDashboardRxType: Reducer = (
  state: AppState,
  action: IAction
): AppState => ({
  ...state,
  filterDate: {
    ...state.filterDate,
    dashboard: {
      ...state.filterDate.dashboard,
      overview: {
        ...state.filterDate.dashboard.overview,
        rx: {
          ...state.filterDate.dashboard.overview.rx,
          start: action.payload.start,
          end: action.payload.end,
          compareStart: action.payload.compareStart,
          compareEnd: action.payload.compareEnd,
        },
      },
    },
  },
});

export const handleChangeFilterDateStoreDetailType: Reducer = (
  state: AppState,
  action: IAction
): AppState => ({
  ...state,
  filterDate: {
    ...state.filterDate,
    dashboard: {
      ...state.filterDate.dashboard,
      store: {
        ...state.filterDate.dashboard.store,
        detail: {
          ...state.filterDate.dashboard.store.detail,
          start: action.payload.start,
          end: action.payload.end,
          compareStart: action.payload.compareStart,
          compareEnd: action.payload.compareEnd,
        },
      },
    },
  },
});

export const handleChangeFilterStoreDetailType: Reducer = (
  state: AppState,
  action: IAction
): AppState => ({
  ...state,
  filter: {
    ...state.filter,
    dashboard: {
      ...state.filter.dashboard,
      store: {
        ...state.filter.dashboard.store,
        detail: action.payload,
      },
    },
  },
});
