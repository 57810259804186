import axios from 'axios';
import { Service } from 'axios-middleware';
import AuthMiddleware from './auth.middleware';
import { resolve } from 'q';

const service = new Service(axios);
service.register([
  new AuthMiddleware(
    () => new Promise<string>(() => resolve('abcdefghijklmno')),
    axios
  ),
]);

export default service;
