export enum ActionType {
  POST_REGISTER = 'POST_REGISTER',
  POST_REGISTER_SUCCESS = 'POST_REGISTER_SUCCESS',
  POST_REGISTER_FAILED = 'POST_REGISTER_FAILED',
  POST_LOGIN = 'POST_LOGIN',
  POST_LOGIN_SUCCESS = 'POST_LOGIN_SUCCESS',
  POST_LOGIN_FAILED = 'POST_LOGIN_FAILED',
  LOAD_TOKEN = 'LOAD_TOKEN',
  LOAD_TOKEN_SUCCESS = 'LOAD_TOKEN_SUCCESS',
  LOAD_TOKEN_FAILED = 'LOAD_TOKEN_FAILED',
  POST_STORE_LOGIN = 'POST_STORE_LOGIN',
  POST_STORE_LOGIN_SUCCESS = 'POST_STORE_LOGIN_SUCCESS',
  POST_STORE_LOGIN_FAILED = 'POST_STORE_LOGIN_FAILED',
  LOAD_STORE_TOKEN = 'LOAD_STORE_TOKEN',
  LOAD_STORE_TOKEN_SUCCESS = 'LOAD_STORE_TOKEN_SUCCESS',
  LOAD_STORE_TOKEN_FAILED = 'LOAD_STORE_TOKEN_FAILED',
  LOAD_STORE_DATA = 'LOAD_STORE_DATA',
  LOAD_STORE_DATA_SUCCESS = 'LOAD_STORE_DATA_SUCCESS',
  LOAD_STORE_DATA_FAILED = 'LOAD_STORE_DATA_FAILED',
  USER_LOGOUT = 'USER_LOGOUT',
  STORE_LOGOUT = 'STORE_LOGOUT',
  GET_PROJECTS = 'GET_PROJECTS',
  GET_PROJECTS_SUCCESS = 'GET_PROJECTS_SUCCESS',
  GET_PROJECTS_FAILED = 'GET_PROJECTS_FAILED',
  CREATE_PROJECT = 'CREATE_PROJECT',
  CREATE_PROJECT_SUCCESS = 'CREATE_PROJECT_SUCCESS',
  CREATE_PROJECT_FAILED = 'CREATE_PROJECT_FAILED',
  SELECT_PROJECT = 'SELECT_PROJECT',
  GET_CAMPAIGNS = 'GET_CAMPAIGNS',
  GET_CAMPAIGNS_SUCCESS = 'GET_CAMPAIGNS_SUCCESS',
  GET_CAMPAIGNS_FAILED = 'GET_CAMPAIGNS_FAILED',
  SELECT_CAMPAIGN = 'SELECT_CAMPAIGN',
  RESET_PROJECT_CAMPAIGN = 'RESET_PROJECT_CAMPAIGN',
  GET_CAMPAIGN_KEY_STATISTICS = 'GET_CAMPAIGN_KEY_STATISTICS',
  GET_CAMPAIGN_KEY_STATISTICS_SUCCESS = 'GET_CAMPAIGN_KEY_STATISTICS_SUCCESS',
  GET_CAMPAIGN_KEY_STATISTICS_FAILED = 'GET_CAMPAIGN_KEY_STATISTICS_FAILED',
  GET_CAMPAIGN_CONTENT_STATISTICS = 'GET_CAMPAIGN_CONTENT_STATISTICS',
  GET_CAMPAIGN_CONTENT_STATISTICS_SUCCESS = 'GET_CAMPAIGN_CONTENT_STATISTICS_SUCCESS',
  GET_CAMPAIGN_CONTENT_STATISTICS_FAILED = 'GET_CAMPAIGN_CONTENT_STATISTICS_FAILED',
  CHANGE_FILTER_OVERVIEW_DASHBOARD_RX = 'CHANGE_FILTER_OVERVIEW_DASHBOARD_RX',
  CHANGE_FILTER_DATE_OVERVIEW_DASHBOARD_RX = 'CHANGE_FILTER_DATE_OVERVIEW_DASHBOARD_RX',
  CHANGE_FILTER_DATE_STORE_DETAIL = 'CHANGE_FILTER_DATE_STORE_DETAIL',
  CHANGE_FILTER_STORE_DETAIL = 'CHANGE_FILTER_STORE_DETAIL',
}

export interface IAction {
  type: ActionType;
  payload?: any;
}
