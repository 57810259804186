import dotenv from 'dotenv';
import axios from 'axios';
import service from './middlewares';
import { APIProject, UserData } from '../interfaces/project';
import { APICampaign, APIKeyStatistics } from '../interfaces/campaign';
import { Campaign } from '@feedloop/template-engine';
import { QueryString } from '../typings/request';
import qs from 'qs';

dotenv.config();
service.setHttp(axios);

interface CampaignWithAPIKey {
  apiKey: string;
  campaignGuid: string;
}

interface CampaignHistory {
  id: number;
  guid: string;
  campaignId: number;
  campaignData: Campaign;
  createdAt: Date;
  updatedAt: Date;
}

export interface CampaignQueryStringProps extends QueryString {
  name?: string;
}

export interface IFeedloopService {
  apiUrl: string;
  getUserProfile(token: string): Promise<UserData>;
  getProjects(token: string): Promise<APIProject[]>;
  deleteProject(token: string, projectGuid: string): Promise<boolean>;
  updateProject(token: string, modifiedProject: APIProject): Promise<boolean>;
  createProject(token: string, data: APIProject): Promise<string | undefined>;
  getCampaignsByProjectGuid(token: string, projectGuid: string): Promise<APICampaign[]>;
  getCampaignGuidBySubdomain(subdomain: string): Promise<CampaignWithAPIKey>;
  getCampaignByAPIKey(apiKey: string, campaignGuid: string): Promise<APICampaign>;
  createCampaign(token: string, projectGuid: string, campaign: APICampaign): Promise<string>;
  getCampaignHistory(
    token: string,
    projectGuid: string,
    campaignGuid: string
  ): Promise<CampaignHistory[]>;
  saveCampaignHistory(
    token: string,
    projectGuid: string,
    campaignGuid: string,
    campaign: Campaign
  ): Promise<boolean>;
  getS3JsonUrl(
    token: string,
    projectGuid: string,
    campaignGuid: string,
    filename: string,
    extension: string,
    contentType?: string
  ): Promise<string>;
  updateCampaign(
    token: string,
    projectGuid: string,
    campaignGuid: string,
    campaign: APICampaign
  ): Promise<APICampaign | undefined>;
  getCampaignKeyAnalytics(
    token: string,
    projectGuid: string,
    campaignGuid: string
  ): Promise<APIKeyStatistics>;
  deleteCampaign(token: string, projectGuid: string, campaignGuid: string): Promise<boolean>;
  getCampaignContentAnalytics(
    token: string,
    projectGuid: string,
    campaignGuid: string
  ): Promise<any[]>;
  getCampaignsV2(token: string): Promise<APIProject[]>;
  getCampaignsV3(token: string, projectGuid: string): Promise<APICampaign[]>;
}
class FeedloopService implements IFeedloopService {
  apiUrl: string;

  constructor() {
    this.apiUrl = process.env.API_URL ? process.env.API_URL : 'http://localhost:3000';
  }

  async getUserProfile(token: string): Promise<UserData> {
    try {
      const response = await axios.get(`${this.apiUrl}/v1/users`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        validateStatus: status => {
          return status >= 200 && status < 500;
        },
      });
      if (response.status !== 200) throw new Error('Failed get user profile');
      return response.data.data.user;
    } catch (err) {
      throw err;
    }
  }

  async getProjects(token: string): Promise<APIProject[]> {
    try {
      const response = await axios.get(`${this.apiUrl}/v1/projects?limit=1000`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        validateStatus: status => {
          return status >= 200 && status < 500;
        },
      });
      if (response.status !== 200) throw new Error('Failed get projects');
      return response.data.data.projects;
    } catch (err) {
      throw err;
    }
  }
  async getCampaignsV2(token: string): Promise<APIProject[]> {
    try {
      const response = await axios.get(`${this.apiUrl}/v2/campaigns`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        validateStatus: status => {
          return status >= 200 && status < 500;
        },
      });
      if (response.status !== 200) throw new Error('Failed get projects and campaigns');
      return response.data.data;
    } catch (err) {
      throw err;
    }
  }
  async getCampaignsV3(
    token: string,
    projectGuid: string,
    campaignGuid?: string,
    queryStrings?: CampaignQueryStringProps
  ): Promise<APICampaign[]> {
    const preparedQueryStrings = {
      ...(queryStrings || { limit: 1000 }),
      ...(campaignGuid ? { guid: campaignGuid } : {}),
    };
    try {
      const response = await axios.get(
        `${this.apiUrl}/v3/projects/${projectGuid}/campaigns?${qs.stringify(preparedQueryStrings)}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          validateStatus: status => {
            return status >= 200 && status < 500;
          },
        }
      );
      if (response.status !== 200) throw new Error('Failed get projects and campaigns');
      return response.data.data.campaigns;
    } catch (err) {
      throw err;
    }
  }
  async deleteProject(token: string, projectGuid: string): Promise<boolean> {
    try {
      const { status } = await axios.delete(`${this.apiUrl}/v1/projects/${projectGuid}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        validateStatus: status => {
          return status >= 200 && status < 500;
        },
      });
      if (status !== 200) return false;
      return true;
    } catch (err) {
      throw err;
    }
  }
  async updateProject(token: string, modifiedProject: APIProject): Promise<boolean> {
    try {
      const { status } = await axios.patch(
        `${this.apiUrl}/v1/projects/PROJECT-${modifiedProject.guid.replace('PROJECT-', '')}`,
        modifiedProject,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          validateStatus: status => {
            return status >= 200 && status < 500;
          },
        }
      );
      if (status !== 200) return false;
      return true;
    } catch (err) {
      throw err;
    }
  }

  async createProject(token: string, project: APIProject): Promise<string | undefined> {
    try {
      const { data, status } = await axios.post(`${this.apiUrl}/v1/projects`, project, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        validateStatus: status => {
          return status >= 200 && status < 500;
        },
      });
      if (status !== 200 || !data.data.guid) return;
      return data.data.guid;
    } catch (err) {
      return;
    }
  }

  async getCampaignsByProjectGuid(token: string, projectGuid: string): Promise<APICampaign[]> {
    try {
      const { data, status } = await axios.get(
        `${this.apiUrl}/v1/projects/PROJECT-${projectGuid.replace('PROJECT-', '')}/campaigns`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          validateStatus: status => {
            return status >= 200 && status < 500;
          },
        }
      );
      if (status !== 200) throw new Error('Failed to get campaigns by projectGuid');
      return data.data.campaigns;
    } catch (err) {
      throw err;
    }
  }

  async getCampaignGuidBySubdomain(subdomain: string): Promise<CampaignWithAPIKey> {
    try {
      const { data, status } = await axios.get(`${this.apiUrl}/v1/subdomains/${subdomain}`, {
        validateStatus: status => {
          return status >= 200 && status < 500;
        },
      });
      if (status !== 200) throw new Error('Failed to get campaignGuid by subdomain');
      return data.data;
    } catch (err) {
      throw err;
    }
  }

  async getCampaignByAPIKey(apiKey: string, campaignGuid: string): Promise<APICampaign> {
    try {
      const { data, status } = await axios.get(`${this.apiUrl}/v1/websdk/campaigns`, {
        headers: {
          Authorization: apiKey,
        },
        validateStatus: status => {
          return status >= 200 && status < 500;
        },
      });
      if (status !== 200) throw new Error('Failed to get campaign by API key');
      let campaign: APICampaign | null = null;
      (data.data.campaigns as APICampaign[]).forEach((c: APICampaign) => {
        if (c.guid === campaignGuid) campaign = c;
      });
      if (!campaign) throw new Error('Campaign not found');
      return campaign;
    } catch (err) {
      throw err;
    }
  }

  async createCampaign(token: string, projectGuid: string, campaign: APICampaign): Promise<string> {
    try {
      const { data, status } = await axios.post(
        `${this.apiUrl}/v1/projects/PROJECT-${projectGuid.replace('PROJECT-', '')}/campaigns`,
        campaign,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          validateStatus: status => {
            return status >= 200 && status < 500 && status !== 420;
          },
        }
      );
      if (status !== 200) throw new Error('Failed to create campaign');
      return data.data.campaignGuid.guid;
    } catch (err) {
      throw err;
    }
  }

  async getCampaignHistory(
    token: string,
    projectGuid: string,
    campaignGuid: string
  ): Promise<CampaignHistory[]> {
    try {
      const { data, status } = await axios.get(
        `${this.apiUrl}/v1/projects/${projectGuid}/campaigns/${campaignGuid}/campaign-histories`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          validateStatus: status => {
            return status >= 200 && status < 500;
          },
        }
      );
      if (status !== 200) throw new Error('Failed to get campaign histories');
      return data.data.campaignHistories;
    } catch (err) {
      throw err;
    }
  }

  async saveCampaignHistory(
    token: string,
    projectGuid: string,
    campaignGuid: string,
    campaign: Campaign
  ): Promise<boolean> {
    try {
      const { status } = await axios.post(
        `${this.apiUrl}/v1/projects/${projectGuid}/campaigns/${campaignGuid}/campaign-histories`,
        {
          campaignData: campaign,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          validateStatus: status => {
            return status >= 200 && status < 500;
          },
        }
      );
      if (status !== 200) return false;
      return true;
    } catch (err) {
      throw err;
    }
  }

  async getS3JsonUrl(
    token: string,
    projectGuid: string,
    campaignGuid: string,
    filename: string,
    extension: string,
    contentType?: string
  ): Promise<string> {
    try {
      const { data, status } = await axios.get(
        `${this.apiUrl}/v1/projects/${projectGuid}/campaigns/${campaignGuid}/aws-s3-url`,
        {
          params: {
            key: filename,
            extension,
          },
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': contentType || 'application/json',
          },
          validateStatus: status => {
            return status >= 200 && status < 500;
          },
          data: {},
        }
      );
      if (status !== 200) throw new Error('Failed to get S3 JSON URL');
      return data.data;
    } catch (err) {
      throw err;
    }
  }

  async updateCampaign(
    token: string,
    projectGuid: string,
    campaignGuid: string,
    campaign: APICampaign
  ): Promise<APICampaign | undefined> {
    try {
      const response = await axios.patch(
        `${this.apiUrl}/v1/projects/PROJECT-${projectGuid.replace(
          'PROJECT-',
          ''
        )}/campaigns/CAMPAIGN-${campaignGuid.replace('CAMPAIGN-', '')}`,
        campaign,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          validateStatus: status => {
            return status >= 200 && status < 500;
          },
        }
      );
      if (response.status !== 200) return;
      return response.data.data.campaign;
    } catch (err) {
      throw err;
    }
  }

  async getCampaignKeyAnalytics(
    token: string,
    projectGuid: string,
    campaignGuid: string
  ): Promise<APIKeyStatistics> {
    try {
      const { data, status } = await axios.get(
        `${this.apiUrl}/v1/projects/${projectGuid}/campaigns/${campaignGuid}/key-statistics`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          validateStatus: status => {
            return status >= 200 && status < 500;
          },
        }
      );
      if (status !== 200) throw new Error('Failed to get campaign key analytics');
      return data.data.campaignStatistic;
    } catch (err) {
      throw err;
    }
  }
  async deleteCampaign(token: string, projectGuid: string, campaignGuid: string): Promise<boolean> {
    try {
      const { status } = await axios.delete(
        `${this.apiUrl}/v1/projects/${projectGuid}/campaigns/${campaignGuid}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          validateStatus: status => {
            return status >= 200 && status < 500;
          },
        }
      );
      if (status !== 200) return false;
      return true;
    } catch (err) {
      throw err;
    }
  }
  async getCampaignContentAnalytics(
    token: string,
    projectGuid: string,
    campaignGuid: string
  ): Promise<any[]> {
    try {
      const { data, status } = await axios.get(
        `${this.apiUrl}/v1/projects/${projectGuid}/campaigns/${campaignGuid}/content-statistics`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          validateStatus: status => {
            return status >= 200 && status < 500;
          },
        }
      );
      if (status !== 200) throw new Error('Failed to get campaign content analytics');
      return data.data;
    } catch (err) {
      throw err;
    }
  }
}

export default FeedloopService;
