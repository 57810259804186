import AuthService from '../services/auth.service';
import { IAction, ActionType } from '../interfaces/action';
import Storage from '../utils/storage';
import FeedloopService from '../services/feedloop.service';
import { UserData } from '../interfaces/project';

export const loadToken = (dispatch: React.Dispatch<IAction>) => {
  dispatch({
    type: ActionType.LOAD_TOKEN,
  });
  try {
    const token = Storage.load('token');
    if (!token || !token.token) throw new Error('Error load token from local storage');
    return dispatch({
      type: ActionType.LOAD_TOKEN_SUCCESS,
      payload: token.token,
    });
  } catch (err) {
    return dispatch({
      type: ActionType.LOAD_TOKEN_FAILED,
    });
  }
};

export const signin = async (
  email: string,
  password: string,
  dispatch: React.Dispatch<IAction>
) => {
  dispatch({
    type: ActionType.POST_LOGIN,
  });
  try {
    const response = await new AuthService().signin(email, password);
    return dispatch({
      type: ActionType.POST_LOGIN_SUCCESS,
      payload: response.payload,
    });
  } catch (err) {
    return dispatch({
      type: ActionType.POST_LOGIN_FAILED,
    });
  }
};

export const loadStoreToken = (slug: string, dispatch: React.Dispatch<IAction>) => {
  dispatch({
    type: ActionType.LOAD_STORE_TOKEN,
  });
  try {
    if (!Storage.load('storeToken')) throw new Error('Error load store token from local storage');
    return dispatch({
      type: ActionType.LOAD_STORE_TOKEN_SUCCESS,
      payload: Storage.load('storeToken'),
    });
  } catch (err) {
    return dispatch({
      type: ActionType.LOAD_STORE_TOKEN_FAILED,
    });
  }
};

export const storeSignin = async (
  slug: string,
  password: string,
  dispatch: React.Dispatch<IAction>
) => {
  dispatch({
    type: ActionType.POST_STORE_LOGIN,
  });
  try {
    const response = await new AuthService().storeSignin(slug, password);
    return dispatch({
      type: ActionType.POST_STORE_LOGIN_SUCCESS,
      payload: response.payload,
    });
  } catch (err) {
    console.log({ err });
    return dispatch({
      type: ActionType.POST_STORE_LOGIN_FAILED,
      payload: err,
    });
  }
};

export const signup = async (
  email: string,
  password: string,
  fullname: string,
  phoneNumber: string,
  companyName: string,
  companySize: number,
  dispatch: React.Dispatch<IAction>
) => {
  dispatch({
    type: ActionType.POST_REGISTER,
  });
  try {
    const token = await new AuthService().signup(
      email,
      password,
      fullname,
      phoneNumber,
      companyName,
      companySize
    );
    return dispatch({
      type: ActionType.POST_REGISTER_SUCCESS,
      payload: token,
    });
  } catch (err) {
    return dispatch({
      type: ActionType.POST_REGISTER_FAILED,
    });
  }
};

export const signout = async (dispatch: React.Dispatch<IAction>) => {
  if (Storage.remove('token')) {
    dispatch({
      type: ActionType.USER_LOGOUT,
    });
  }
};

export const getUserProfile = async (token: string): Promise<UserData> => {
  try {
    const userData = await new FeedloopService().getUserProfile(token);
    return userData;
  } catch (err) {
    throw err;
  }
};
