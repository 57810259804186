import React from 'react';
import { GlobalStore, AppState, Reducer } from '../interfaces/store';
import { IAction, ActionType } from '../interfaces/action';
import initialState from '../reducers/initialState';
import * as AuthReducer from '../reducers/auth';
import * as StoreReducer from '../reducers/store';
import * as RegisterReducer from '../reducers/register';
import * as ProjectReducer from '../reducers/project';
import * as CampaignReducer from '../reducers/campaign';
import * as FilterReducer from '../reducers/filter';

const handlerMap: Map<ActionType, Reducer> = new Map<ActionType, Reducer>();
/* REGISTER */
handlerMap.set(ActionType.POST_REGISTER, RegisterReducer.handlePostRegisterType);
handlerMap.set(ActionType.POST_REGISTER_SUCCESS, RegisterReducer.handlePostRegisterSuccessType);
handlerMap.set(ActionType.POST_REGISTER_FAILED, RegisterReducer.handlePostRegisterFailedType);

/* AUTH */
handlerMap.set(ActionType.POST_LOGIN, AuthReducer.handlePostLoginType);
handlerMap.set(ActionType.POST_LOGIN_SUCCESS, AuthReducer.handlePostLoginSuccessType);
handlerMap.set(ActionType.POST_LOGIN_FAILED, AuthReducer.handlePostLoginFailedType);
handlerMap.set(ActionType.LOAD_TOKEN, AuthReducer.handleLoadTokenType);
handlerMap.set(ActionType.LOAD_TOKEN_SUCCESS, AuthReducer.handleLoadTokenSuccessType);
handlerMap.set(ActionType.LOAD_TOKEN_FAILED, AuthReducer.handleLoadTokenFailedType);
handlerMap.set(ActionType.USER_LOGOUT, AuthReducer.handleUserLogoutType);
/* STORE */
handlerMap.set(ActionType.POST_STORE_LOGIN, StoreReducer.handlePostStoreLoginType);
handlerMap.set(ActionType.POST_STORE_LOGIN_SUCCESS, StoreReducer.handlePostStoreLoginSuccessType);
handlerMap.set(ActionType.POST_STORE_LOGIN_FAILED, StoreReducer.handlePostStoreLoginFailedType);
handlerMap.set(ActionType.LOAD_STORE_TOKEN, StoreReducer.handleLoadStoreTokenType);
handlerMap.set(ActionType.LOAD_STORE_TOKEN_SUCCESS, StoreReducer.handleLoadStoreTokenSuccessType);
handlerMap.set(ActionType.LOAD_STORE_TOKEN_FAILED, StoreReducer.handleLoadStoreTokenFailedType);
handlerMap.set(ActionType.LOAD_STORE_DATA, StoreReducer.handleLoadStoreDataType);
handlerMap.set(ActionType.LOAD_STORE_DATA_SUCCESS, StoreReducer.handleLoadStoreDataSuccessType);
handlerMap.set(ActionType.LOAD_STORE_DATA_FAILED, StoreReducer.handleLoadStoreDataFailedType);
handlerMap.set(ActionType.STORE_LOGOUT, StoreReducer.handleStoreLogoutType);

/* PROJECT */
handlerMap.set(ActionType.GET_PROJECTS, ProjectReducer.handleGetProjectsType);
handlerMap.set(ActionType.GET_PROJECTS_SUCCESS, ProjectReducer.handleGetProjectsSuccessType);
handlerMap.set(ActionType.GET_PROJECTS_FAILED, ProjectReducer.handleGetProjectsFailedType);
handlerMap.set(ActionType.CREATE_PROJECT, ProjectReducer.handleCreateProjectType);
handlerMap.set(ActionType.CREATE_PROJECT_SUCCESS, ProjectReducer.handleCreateProjectSuccessType);
handlerMap.set(ActionType.CREATE_PROJECT_FAILED, ProjectReducer.handleCreateProjectFailedType);
handlerMap.set(ActionType.SELECT_PROJECT, ProjectReducer.handleSelectProjectType);
handlerMap.set(ActionType.RESET_PROJECT_CAMPAIGN, ProjectReducer.handleResetProjectCampaignType);
/* FILTER */
handlerMap.set(
  ActionType.CHANGE_FILTER_OVERVIEW_DASHBOARD_RX,
  FilterReducer.handleChangeFilterOverviewDashboardRxType
);
handlerMap.set(
  ActionType.CHANGE_FILTER_DATE_OVERVIEW_DASHBOARD_RX,
  FilterReducer.handleChangeFilterDateOverviewDashboardRxType
);
handlerMap.set(
  ActionType.CHANGE_FILTER_STORE_DETAIL,
  FilterReducer.handleChangeFilterStoreDetailType
);
handlerMap.set(
  ActionType.CHANGE_FILTER_DATE_STORE_DETAIL,
  FilterReducer.handleChangeFilterDateStoreDetailType
);
/* CAMPAIGN */
handlerMap.set(ActionType.GET_CAMPAIGNS, CampaignReducer.handleGetCampaignsType);
handlerMap.set(ActionType.GET_CAMPAIGNS_SUCCESS, CampaignReducer.handleGetCampaignsSuccessType);
handlerMap.set(ActionType.GET_CAMPAIGNS_FAILED, CampaignReducer.handleGetCampaignsFailedType);
handlerMap.set(ActionType.SELECT_CAMPAIGN, CampaignReducer.handleSelectCampaignType);
handlerMap.set(
  ActionType.GET_CAMPAIGN_KEY_STATISTICS,
  CampaignReducer.handleGetCampaignKeyStatisticsType
);
handlerMap.set(
  ActionType.GET_CAMPAIGN_KEY_STATISTICS_SUCCESS,
  CampaignReducer.handleGetCampaignKeyStatisticsSuccessType
);
handlerMap.set(
  ActionType.GET_CAMPAIGN_KEY_STATISTICS_FAILED,
  CampaignReducer.handleGetCampaignKeyStatisticsFailedType
);
handlerMap.set(
  ActionType.GET_CAMPAIGN_CONTENT_STATISTICS,
  CampaignReducer.handleGetCampaignContentStatisticsType
);
handlerMap.set(
  ActionType.GET_CAMPAIGN_CONTENT_STATISTICS_SUCCESS,
  CampaignReducer.handleGetCampaignContentStatisticsSuccessType
);
handlerMap.set(
  ActionType.GET_CAMPAIGN_CONTENT_STATISTICS_FAILED,
  CampaignReducer.handleGetCampaignContentStatisticsFailedType
);
export const rootReducer: Reducer = (state: AppState = initialState, action: IAction) => {
  const fn = handlerMap.get(action.type);
  if (fn) return fn(state, action);
  return state;
};

export const Store = React.createContext<GlobalStore>({
  state: initialState,
} as GlobalStore);

export const useStore = () => React.useContext(Store);

interface StoreProviderProps {
  children: any;
}
export const StoreProvider = (props: StoreProviderProps) => {
  const [state, dispatch] = React.useReducer(rootReducer, initialState);
  const store: GlobalStore = React.useMemo(() => ({ state, dispatch }), [state]);
  return <Store.Provider value={store}>{props.children}</Store.Provider>;
};
