import React from 'react';
import Axios from 'axios';
import LazyJS from 'lazy.js';
import { Map as ImmutableMap } from 'immutable';

import { TemplateConfig } from '@feedloop/template-engine';
import CampaignBuilder from '@feedloop/template-engine/lib/builder/CampaignBuilder';

import * as campaignActions from '../actions/campaign';
import { APIProject } from '../interfaces/project';
import { APICampaignStatus, APICampaign } from '../interfaces/campaign';
import { ApiResponse } from '../interfaces/common';
import FeedloopService from '../services/feedloop.service';
import themeConfigUtils from '../utils/themeConfig';
import CampaignMixer from '../utils/campaignMixer';

import { CampaignHistoriesResponse, CampaignHistory } from './campaign/types';
import useFeedloopService from '../hooks/useFeedloopService';
import produce from 'immer';
import { KanvasCampaign } from '@feedloop/kanvas/lib/editor/KanvasEditorContext';
import { useStore } from '../store';
import { ProjectQueryStringProps } from './project/projectHooks';

const feedloop = new FeedloopService();

const initialProjectManagerState = {
  campaigns: [],
  projects: new Map<string, APIProject>(),
  loading: false,
  publishing: false,
};

const ProjectManagerContext = React.createContext<{
  state: {
    campaigns: APICampaign[];
    projects: Map<string, APIProject>;
    loading: boolean;
    publishing: boolean;
    error?: Error;
  };
  syncProjects: (token: string) => void;
  syncCampaigns: (
    token: string,
    projectGuid: string,
    queryString?: ProjectQueryStringProps
  ) => void;
  getProject: (projectGuid: string) => APIProject | undefined;
  getCampaign: (projectGuid: string, campaignGuid: string) => APICampaign | undefined;
  changeCampaignStatus: (
    active: boolean,
    projectGuid: string,
    campaignGuid: string,
    token: string
  ) => Promise<void>;
  changeCampaignName: (
    name: string,
    projectGuid: string,
    campaignGuid: string,
    token: string
  ) => Promise<void>;
  publish: (projectGuid: string, campaignGuid: string, token: string) => Promise<void>;
  saveAsTemplate: (
    projectGuid: string,
    campaignGuid: string,
    token: string,
    title: string,
    description: string
  ) => Promise<void>;
  createProject: (project: APIProject, token: string) => Promise<string>;
  updateProject: (modifiedProject: APIProject, token: string) => Promise<void>;
  deleteProject: (projectGuid: string, token: string) => Promise<void>;
}>({
  state: initialProjectManagerState,
  syncProjects: _token => undefined,
  syncCampaigns: (_token, _projectGuid) => undefined,
  getProject: _pGuid => undefined,
  getCampaign: (_pGuid, _cGuid) => undefined,
  changeCampaignStatus: (_active, _pGuid, _cGuid, _token) => new Promise(res => res(undefined)),
  changeCampaignName: (_name, _pGuid, _cGuid, _token) => new Promise(res => res(undefined)),
  publish: (_pGuid, _cGuid, _token) => new Promise(res => res(undefined)),
  saveAsTemplate: (_pGuid, _cGuid, _token) => new Promise(res => res(undefined)),
  createProject: (_project, _token) => new Promise(res => res(undefined)),
  updateProject: (_modifiedProject, _token) => new Promise(res => res(undefined)),
  deleteProject: (_pGuid, _token) => new Promise(res => res(undefined)),
});

const { Provider } = ProjectManagerContext;

const ProjectManagerProvider = (props: { children: React.ReactNode }) => {
  const [loading, setLoading] = React.useState(false);
  const [publishing, setPublising] = React.useState(false);
  const [projects, setProjects] = React.useState(new Map<string, APIProject>());
  const [campaigns, setCampaigns] = React.useState<APICampaign[]>([]);
  const [error, setError] = React.useState(undefined);
  const { publishCampaign, saveCampaignAsTemplate } = useFeedloopService();
  const {
    state: {
      auth: { token },
    },
  } = useStore();
  const state = {
    error,
    publishing,
    projects,
    loading,
    campaigns,
  };

  const syncProjects = React.useCallback(
    async token => {
      try {
        setError(undefined);
        setLoading(true);
        const projectResponse = await feedloop.getProjects(token);
        setLoading(false);
        setProjects(new Map(projectResponse.map(project => [project.guid, project])));
      } catch (err) {
        setLoading(false);
        setError(err);
      }
    },
    [feedloop]
  );
  const syncCampaigns = React.useCallback(
    async (token, projectGuid, queryString?: ProjectQueryStringProps) => {
      try {
        setError(undefined);
        setLoading(true);
        const response = await feedloop.getCampaignsV3(token, projectGuid, undefined, queryString);
        setLoading(false);
        setCampaigns(response);
      } catch (err) {
        setLoading(false);
        setError(err);
      }
    },
    [feedloop]
  );

  const getProject = React.useCallback(
    projectGuid => {
      return projects.get(projectGuid);
    },
    [projects]
  );

  const getCampaign = React.useCallback(
    (projectGuid, campaignGuid) => {
      const project = getProject(projectGuid);
      return project && project.campaigns && project.campaigns.find(ca => ca.guid === campaignGuid);
    },
    [getProject]
  );

  const changeCampaignStatus = React.useCallback(
    async (active, projectGuid, campaignGuid, token) => {
      const campaign = getCampaign(projectGuid, campaignGuid);
      if (!campaign) throw Error('Error loading campaign');

      const succeed = await campaignActions.changeCampaignStatus(token, projectGuid, campaignGuid, {
        ...campaign,
        status: active ? APICampaignStatus.ACTIVE : APICampaignStatus.DISABLED,
      });
      if (succeed) {
        syncProjects(token);
        return;
      }
      throw Error('Something went wrong!');
    },
    [campaignActions, APICampaignStatus, getCampaign, syncProjects]
  );

  const changeCampaignName = React.useCallback(
    async (name, projectGuid, campaignGuid, token) => {
      const campaign = getCampaign(projectGuid, campaignGuid);
      if (!campaign) throw Error('Error loading campaign');

      await campaignActions.changeCampaignStatus(token, projectGuid, campaignGuid, {
        ...campaign,
        name,
      });
      syncProjects(token);
    },
    [getCampaign, syncProjects, campaignActions]
  );

  const createProject = React.useCallback(
    async (project, token) => {
      setLoading(true);
      try {
        const guid = await feedloop.createProject(token, project);
        if (!guid) throw Error('Cannot create project');
        syncProjects(token);
        return guid;
      } catch (err) {
        setLoading(false);
        throw err;
      }
    },
    [feedloop]
  );

  const updateProject = React.useCallback(
    async (modifiedProject, token) => {
      setLoading(true);
      try {
        const response = await feedloop.updateProject(token, modifiedProject);
        if (!response) throw Error('Cannot update project');
        syncProjects(token);
      } catch (err) {
        setLoading(false);
        throw err;
      }
    },
    [feedloop]
  );

  const deleteProject = React.useCallback(
    async (guid, token) => {
      setLoading(true);
      try {
        const response = await feedloop.deleteProject(token, guid);
        if (!response) throw Error('Cannot delete project');
        syncProjects(token);
      } catch (err) {
        setLoading(false);
        throw err;
      }
    },
    [feedloop]
  );

  const getPath = React.useCallback((projectGuid: string, campaignGuid: string) => {
    const resourcePath = `${process.env.API_URL ||
      ''}/v1/projects/${projectGuid}/campaigns/${campaignGuid}`;
    const historyPath = `${resourcePath}/campaign-histories`;
    return { resourcePath, historyPath };
  }, []);

  const getLastCampaignFromHistory = React.useCallback(
    async (historyPath: string, token: string) => {
      const campaignHistories = await Axios.get<CampaignHistoriesResponse>(historyPath, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const lastCampaignFromHistory = LazyJS(
        campaignHistories.data.data.campaignHistories
      ).max(history => new Date(history.createdAt).valueOf());
      return lastCampaignFromHistory;
    },
    []
  );

  const getApiCampaign = React.useCallback(
    (projectGuid: string, campaignGuid: string) => {
      const project = projects && projects.get(projectGuid);
      if (!project) return false;
      const listCampaigns = campaigns.length > 0 ? campaigns : project.campaigns || [];
      const apiCampaign =
        project && listCampaigns && listCampaigns.find(ca => ca.guid === campaignGuid);
      return apiCampaign;
    },
    [projects, campaigns]
  );

  const isThemeSupported = React.useCallback((campaignBuilder: CampaignBuilder) => {
    const campaign = campaignBuilder.toCampaign();
    const templatesMap: ImmutableMap<string, TemplateConfig> = ImmutableMap(
      campaignBuilder.themeConfig.templates.map((template: TemplateConfig) => [
        template.name,
        template,
      ])
    );
    const unsupported = [
      ...themeConfigUtils.collectUnsupported(campaign.mainPages, 'main', templatesMap),
      ...themeConfigUtils.collectUnsupported(campaign.results, 'result', templatesMap),
      ...themeConfigUtils.collectUnsupported(campaign.inactivePages, 'inactive', templatesMap),
    ];
    return unsupported.length === 0;
  }, []);

  const publish = React.useCallback(
    async (projectGuid: string, campaignGuid: string, token: string) => {
      setPublising(true);
      try {
        const { historyPath } = getPath(projectGuid, campaignGuid);
        const lastCampaignFromHistory = await getLastCampaignFromHistory(historyPath, token);

        const apiCampaign = getApiCampaign(projectGuid, campaignGuid);
        if (!apiCampaign) throw new Error('campaign was not loaded properly');

        const builder = await CampaignBuilder.fromJSON(lastCampaignFromHistory.campaignData as any);
        const kanvasSchema =
          apiCampaign.type === 'kanvas'
            ? ((lastCampaignFromHistory.campaignData as unknown) as KanvasCampaign)
            : undefined;
        const legacySchema =
          apiCampaign.type === 'legacy'
            ? (
                await CampaignBuilder.fromJSON(lastCampaignFromHistory.campaignData as any)
              ).toCampaign()
            : undefined;
        const updatedApiCampaign = produce(apiCampaign, draft => {
          draft.schema = draft.type === 'kanvas' ? kanvasSchema : legacySchema;
        });

        if (apiCampaign.type === 'legacy' && !isThemeSupported(builder))
          throw Error(
            'Some contents is unsupported by theme ' +
              (legacySchema ? legacySchema.themeNamespace : '')
          );

        await publishCampaign(projectGuid, campaignGuid, updatedApiCampaign);
        setPublising(false);
      } catch (err) {
        setPublising(false);
        throw err;
      }
    },
    [projects, campaigns]
  );

  const saveAsTemplate = React.useCallback(
    async (
      projectGuid: string,
      campaignGuid: string,
      token: string,
      title: string,
      description: string
    ) => {
      setLoading(true);
      try {
        const { historyPath } = getPath(projectGuid, campaignGuid);
        const lastCampaignFromHistory = await getLastCampaignFromHistory(historyPath, token);

        const apiCampaign = getApiCampaign(projectGuid, campaignGuid);
        if (!apiCampaign) throw new Error('campaign was not loaded properly');

        const builder = await CampaignBuilder.fromJSON(lastCampaignFromHistory.campaignData as any);
        const campaign = builder.toCampaign();

        if (!isThemeSupported(builder))
          throw Error('Some contents is unsupported by theme ' + campaign.themeNamespace);

        await saveCampaignAsTemplate(projectGuid, campaign, title, description);
      } catch (error) {
        throw error;
      } finally {
        setLoading(false);
      }
    },
    [projects]
  );

  return (
    <Provider
      value={{
        state,
        getProject,
        getCampaign,
        syncProjects,
        changeCampaignStatus,
        changeCampaignName,
        publish,
        saveAsTemplate,
        createProject,
        updateProject,
        deleteProject,
        syncCampaigns,
      }}
    >
      {props.children}
    </Provider>
  );
};

export const useProjectManagerContext = () => {
  return React.useContext(ProjectManagerContext);
};

export default ProjectManagerProvider;
