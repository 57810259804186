import React, { ReactNode } from 'react';
import './maya.less';
import './style.css';
import { Layout } from '@feedloop/feedloop-antd';
const ls = window.localStorage;

const NewThemeWrapper = (props: { children: ReactNode }) => {
  const [style, setStyle] = React.useState({
    height: '100vh',
    overflow: 'auto',
    display: 'flex',
  });
  React.useEffect(() => {
    if (ls.getItem('theme') !== 'new') {
      ls.setItem('theme', 'new');
      window.location.reload();
    }
  }, []);
  return <Layout style={style}>{props.children}</Layout>;
};
export default NewThemeWrapper;
