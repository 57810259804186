import dotenv from 'dotenv';
import axios from 'axios';
import { Campaign } from '@feedloop/template-engine';

dotenv.config();

class S3Service {
  s3Url: string;

  constructor() {
    this.s3Url = process.env.S3_URL ? process.env.S3_URL : 'http://localhost:3000';
  }

  async getMicrositeData(campaignGuid: string): Promise<Campaign | null> {
    try {
      const { data, status } = await axios.get(
        `${this.s3Url}/${campaignGuid}/templates/campaign.json`,
        {
          headers: {
            'Cache-Control': 'no-cache',
          },
          validateStatus: status => {
            return status >= 200 && status < 500;
          },
        }
      );
      if (status !== 200) return null;
      return data;
    } catch (err) {
      return null;
    }
  }

  async uploadCampaignJSON(storageUrl: string, campaign: Campaign): Promise<boolean> {
    try {
      const { status } = await axios.put(storageUrl, JSON.stringify(campaign), {
        headers: {
          'Content-Type': 'application/json',
        },
        validateStatus: status => {
          return status >= 200 && status < 500;
        },
      });
      if (status !== 200) return false;
      return true;
    } catch (err) {
      throw err;
    }
  }
}

export default S3Service;
