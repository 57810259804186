interface APIContentVariable {
  name: string;
  referenceId: string;
}

enum APIVariableOperator {
  ADD = 'add',
  SUBSTRACT = 'substract',
  MULTIPLY = 'multiply',
  DIVIDE = 'divide',
  ASSIGN = 'assign',
  APPEND = 'append',
}

interface APIContentVariableWithOperator extends APIContentVariable {
  operator: APIVariableOperator;
  value: string;
}

interface APIContentChoice {
  label: string;
  referenceId: string;
  imageUrl?: string;
}

export enum APIContentType {
  MULTIPLE_CHOICE = 'feedbackChoice',
  NUMBER_SLIDER = 'feedbackRating',
  FREETEXT_INPUT = 'feedbackText',
  NPS = 'feedbackNps',
  DROPDOWN = 'dropdown',
  HIDDEN = 'hidden',
}

export interface APIContent {
  tag: string;
  contentGuid: string;
  contentType: APIContentType;
  attributes: any;
}

export interface APIMultipleChoiceContent extends APIContent {
  contentType: APIContentType.MULTIPLE_CHOICE;
  attributes: {
    title: string;
    text: string;
    variables: APIContentVariableWithOperator[];
    choices: APIContentChoice[];
  };
}

enum APINumberSliderVisual {
  STAR = 'star',
  SMILE = 'smile',
  NUMBER = 'number',
}

export interface APINumberSliderContent extends APIContent {
  contentType: APIContentType.NUMBER_SLIDER;
  attributes: {
    title: string;
    text: string;
    visual: APINumberSliderVisual;
    min: number;
    max: number;
  };
}

enum APIFreeTextValidator {
  EMAIL = 'email',
  NUMBER = 'number',
  URL = 'url',
  REGEX = 'regex',
}

export interface APIFreetextInputContent extends APIContent {
  contentType: APIContentType.FREETEXT_INPUT;
  attributes: {
    title: string;
    text: string;
    validator: APIFreeTextValidator;
    regex: string;
    buttonLabel: string;
  };
}

export interface APIDropdownContent extends APIContent {
  contentType: APIContentType.DROPDOWN;
  attributes: {
    title: string;
    variables: APIContentVariableWithOperator[];
    choices: APIContentChoice[];
  };
}

export interface APIHiddenContent extends APIContent {
  contentType: APIContentType.HIDDEN;
  attributes: {
    variables: APIContentVariable[];
  };
}
