import { APICampaign } from './campaign';

/*
ABOUT PROJECT STATUS

Dulu, project itu bisa dibuat "tidak aktif".
Sekarang, project itu selalu "aktif".
Dan subscription billing akan diikat ke project.
Jadi, sekarang hanya ada status "pro" & "free".

Last updated 2019/07/09 11:44 UTC+7
by: Krisna F.
*/
export enum APIProjectStatus {
  /* LEGACY STATUS */
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  /* NEWEST STATUS */
  FREE = 'free',
  PRO = 'pro',
}

export interface APIProject {
  id: number;
  userId: number;
  title: string;
  type: string;
  description: string;
  guid: string;
  status: APIProjectStatus;
  logo: string;
  campaigns: APICampaign[];
}

export interface UserData {
  id: number;
  guid: string;
  email: string;
  emailVerified: boolean;
  fullName: string;
  themes: string[];
  level: string;
}
