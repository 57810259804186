import { Reducer, AppState } from '../interfaces/store';
import { IAction } from '../interfaces/action';

export const handleGetCampaignsType: Reducer = (state: AppState, _: IAction): AppState => ({
  ...state,
  campaign: {
    ...state.campaign,
    getLoading: true,
    getError: false,
  },
});

export const handleGetCampaignsSuccessType: Reducer = (
  state: AppState,
  action: IAction
): AppState => ({
  ...state,
  campaign: {
    ...state.campaign,
    getLoading: false,
    getError: false,
    campaigns: action.payload,
  },
});

export const handleGetCampaignsFailedType: Reducer = (state: AppState, _: IAction): AppState => ({
  ...state,
  campaign: {
    ...state.campaign,
    getLoading: false,
    getError: true,
  },
});

export const handleSelectCampaignType: Reducer = (state: AppState, action: IAction): AppState => ({
  ...state,
  campaign: {
    ...state.campaign,
    current: action.payload,
  },
});

export const handleGetCampaignKeyStatisticsType: Reducer = (
  state: AppState,
  _: IAction
): AppState => ({
  ...state,
  campaign: {
    ...state.campaign,
    keyStatisticsLoading: true,
    keyStatisticsError: false,
    keyStatistics: [],
  },
});

export const handleGetCampaignKeyStatisticsSuccessType: Reducer = (
  state: AppState,
  action: IAction
): AppState => ({
  ...state,
  campaign: {
    ...state.campaign,
    keyStatisticsLoading: false,
    keyStatisticsError: false,
    keyStatistics: action.payload,
  },
});

export const handleGetCampaignKeyStatisticsFailedType: Reducer = (
  state: AppState,
  _: IAction
): AppState => ({
  ...state,
  campaign: {
    ...state.campaign,
    keyStatisticsLoading: false,
    keyStatisticsError: true,
  },
});

export const handleGetCampaignContentStatisticsType: Reducer = (
  state: AppState,
  _: IAction
): AppState => ({
  ...state,
  campaign: {
    ...state.campaign,
    contentStatisticsLoading: true,
    contentStatisticsError: false,
    contentStatistics: [],
  },
});

export const handleGetCampaignContentStatisticsSuccessType: Reducer = (
  state: AppState,
  action: IAction
): AppState => ({
  ...state,
  campaign: {
    ...state.campaign,
    contentStatisticsLoading: false,
    contentStatisticsError: false,
    contentStatistics: action.payload,
  },
});

export const handleGetCampaignContentStatisticsFailedType: Reducer = (
  state: AppState,
  _: IAction
): AppState => ({
  ...state,
  campaign: {
    ...state.campaign,
    contentStatisticsLoading: false,
    contentStatisticsError: true,
  },
});
