import { Reducer, AppState } from '../interfaces/store';
import { IAction } from '../interfaces/action';

export const handlePostLoginType: Reducer = (state: AppState, _: IAction): AppState => ({
  ...state,
  auth: {
    ...state.auth,
    loading: true,
    error: false,
    token: '',
  },
});

export const handlePostLoginSuccessType: Reducer = (
  state: AppState,
  action: IAction
): AppState => ({
  ...state,
  auth: {
    ...state.auth,
    loading: false,
    error: false,
    token: action.payload,
  },
});

export const handlePostLoginFailedType: Reducer = (state: AppState, _: IAction): AppState => ({
  ...state,
  auth: {
    ...state.auth,
    loading: false,
    error: true,
  },
});

export const handleLoadTokenType: Reducer = (state: AppState, _: IAction): AppState => ({
  ...state,
  auth: {
    ...state.auth,
    loading: true,
    error: false,
    token: '',
  },
});

export const handleLoadTokenSuccessType: Reducer = (
  state: AppState,
  action: IAction
): AppState => ({
  ...state,
  auth: {
    ...state.auth,
    loaded: true,
    loading: false,
    error: false,
    token: action.payload,
  },
});

export const handleLoadTokenFailedType: Reducer = (state: AppState, _: IAction): AppState => ({
  ...state,
  auth: {
    ...state.auth,
    loaded: true,
    loading: false,
    error: true,
  },
});

export const handleUserLogoutType: Reducer = (state: AppState, _: IAction): AppState => ({
  ...state,
  auth: {
    ...state.auth,
    loaded: false,
    token: '',
  },
});
