import { AxiosInstance, AxiosError, AxiosRequestConfig, AxiosPromise } from 'axios';
import { Middleware } from 'axios-middleware';

interface AxiosRetryableRequestConfig extends AxiosRequestConfig {
  hasRetriedRequest?: boolean;
}

interface AxiosRetryableInstance extends AxiosInstance {
  (config: AxiosRetryableRequestConfig): AxiosPromise;
}

export default class AuthMiddleware implements Middleware {
  auth: () => Promise<any>;
  http: AxiosRetryableInstance;

  constructor(auth: () => Promise<any>, http: AxiosRetryableInstance) {
    this.auth = auth;
    this.http = http;
  }

  isAuthErrorAndNotRetried(err: AxiosError) {
    return (
      err.response &&
      err.response.status === 401 &&
      err.config &&
      !(err.config as AxiosRetryableRequestConfig).hasRetriedRequest
    );
  }

  createRetryableConfigFromError(err: AxiosError, token: string): AxiosRetryableRequestConfig {
    const config: AxiosRetryableRequestConfig = {
      ...err.config,
      hasRetriedRequest: true,
      headers: {
        ...err.config.headers,
        Authorization: `Bearer ${token}`,
      },
    };
    return config;
  }

  onResponseError(err: AxiosError) {
    if (this.isAuthErrorAndNotRetried(err)) {
      return this.auth()
        .then(token => {
          const config = this.createRetryableConfigFromError(err, token);
          this.http(config);
        })
        .catch(error => {
          throw error;
        });
    }
    throw err;
  }
}
