import React from 'react';
import './Loading.css';

const Loading = () => (
  <div className="lds-roller-container">
    <div className="lds-roller">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
    <p>Loading</p>
  </div>
);

export default Loading;
