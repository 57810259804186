import { APIContent, APIContentType } from './content';
import { Content, Campaign } from '@feedloop/template-engine';
import { KanvasCampaign } from '@feedloop/kanvas/lib/editor/KanvasEditorContext';
import { APIProject } from './project';

export enum APICampaignStatus {
  ACTIVE = 'active',
  DISABLED = 'disabled',
  DRAFT = 'draft',
}

export enum APICampaignVariableType {
  NUMERIC = 'numeric',
  STRING = 'string',
}
export interface APIProjectDirectory {
  guid: string;
  name: string;
  schema: { [key: string]: { type: string; items: string } };
  directoryRecords: APIProjectDirectoryRecord[];
}
export interface APIProjectDirectoryRecord {
  guid: string;
  data: { [key: string]: unknown };
}
export interface APICampaignSessionVariable {
  name: string;
  dataType: APICampaignVariableType;
  defaultValue: string;
}
export interface APIWebContent {
  guid: string;
  pageName: 'mainPages' | 'results' | 'inactivePages';
  pageOrder: number;
  order: number;
  schema: Content;
  content?: {
    tag: string;
    contentType: APIContentType;
    attributes: any;
  };
}
export interface APICampaignGlobalVariable extends APICampaignSessionVariable {
  value: string;
  referenceId: string;
}

enum APICampaignResultLogicType {
  AND = 'and',
  OR = 'or',
}

interface APICampaignResultLogic {
  logicType: APICampaignResultLogicType;
  variable: string;
  value: string;
  comparator: '=' | '>' | '<' | '<=' | '>=' | '!=';
}

export enum APIVariableOperator {
  ADD = 'add',
  SUBSTRACT = 'subtract',
  MULTIPLY = 'multiply',
  DIVIDE = 'divide',
  ASSIGN = 'assign',
  APPEND = 'append',
}
interface APICampaignResult {
  result: string;
  logic: APICampaignResultLogic[];
}

export interface APITopic {
  guid: string;
  topic: string;
  question: string;
}

export interface APIMetric {
  guid: string;
  metricTopics: APITopic[];
  name: string;
  type: string;
}

export interface APICampaignVariable {
  guid?: string;
  name: string;
  namespace: string;
  type: 'userGenerated';
  valueType: 'string' | 'number';
}

type LegacyCampaignType = {
  type: 'legacy';
  schema?: Campaign;
};

type KanvasCampaignType = {
  type: 'kanvas';
  schema?: KanvasCampaign;
};

export type APICampaign = (LegacyCampaignType | KanvasCampaignType) & {
  id: number;
  projectId?: number;
  project?: APIProject;
  webContents: APIWebContent[];
  contents: APIContent[];
  guid: string;
  thumbnail?: string;
  status: APICampaignStatus;
  name: string;
  openGraphTitle?: string;
  openGraphDescription?: string;
  openGraphImage?: string;
  gtmId?: string;
  googleAnalyticId?: string;
  facebookPixelId?: string;
  sessionVariables: APICampaignSessionVariable[];
  globalVariables: APICampaignGlobalVariable[];
  campaignVariables: APICampaignVariable[];
  subdomain: string;
  slug: string;
  rules: APICampaignResult[];
  createdAt: string;
  scheduleStart: Date;
  scheduleEnd: Date; // Always filled with 5 years from scheduledStart
  topics?: Partial<APITopic>[];
  /* Start from here everything is forced value */
  deliveryType: 'microsite';
  inPanel: false;
  index: 0;
  persist: true;
  trigger: {
    pages: ['*'];
    triggerType: 'pageload';
  };
  pages: { [key: string]: any };
};

export interface APIKeyStatistics {
  impression: number;
  engagement: number;
  bounceRatePercentage: number;
  completionRatePercentage: number;
  averageTimeToComplete: number;
}
