import React from 'react';

import { getUserProfile } from '../actions/auth';
import { UserData } from '../interfaces/project';
import Storage from '../utils/storage';

const initialAuthState = {
  loading: false,
};

const AuthContext = React.createContext<{
  state: {
    userData?: UserData;
    loading: boolean;
    error?: Error;
    token?: string;
  };
  tokenLogin: (token: string) => void;
  userLogout: () => void;
}>({
  state: initialAuthState,
  tokenLogin: _str => undefined,
  userLogout: () => undefined,
});

const { Provider } = AuthContext;

const AuthProvider = (props: { children: React.ReactNode }) => {
  const [state, setState] = React.useState(initialAuthState);

  const tokenLogin = React.useCallback(
    async token => {
      try {
        setState(initialAuthState);
        const userData = await getUserProfile(token);
        setState(prevState => ({ ...prevState, userData, loading: false, token }));
      } catch (error) {
        Storage.remove('token');
        setTimeout(() => {
          window.location.reload();
        }, 500);
      }
    },
    [getUserProfile]
  );

  const userLogout = React.useCallback(() => {
    setState(() => ({ ...initialAuthState, userData: null }));
  }, []);

  return <Provider value={{ state, tokenLogin, userLogout }}>{props.children}</Provider>;
};

export const useAuthContext = () => {
  return React.useContext(AuthContext);
};

export default AuthProvider;
